* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.webapp label {
  margin-bottom: 0;
}

.margin-right {
  margin-right: 10px;
}

.margin-top {
  margin-top: 10px;
}

.large-margin-top {
  margin-top: 20px;
}

.webapp {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin: auto;
  padding-left: 15px;
  max-width: 800px;
}

.center-text {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.thick {
  margin: 10px;
}

.strikethrough {
  text-decoration: line-through;
}

.align-left {
  text-align: start;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.queue-item {
  word-wrap: anywhere;
}

.smol {
  max-width: 75px;
}

.center-children {
  display: flex;
  align-items: center;
  flex-direction: column;
}