.content {
    height: 20%;
    width: 75%;
    background-color: white;
    padding: 8px;
    margin: 4px;
    border: 3px solid red;
    border-radius: 2em;
}

.vertical,
.horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.horizontal.center,
.vertical.center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.horizontal.wrap,
.vertical.wrap {
    flex-wrap: wrap;
}

.vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.flex {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
}